<template>
  <!-- 渠道商 -->
  <div class="container">
    <a-page-header :title="title" />
    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">监工管理列表</div>
        </div>
        <div class="item">
          <a-button
            v-permission="'add'"
            type="primary"
            @click="$jump({ path: '/fowllerAdd' })"
          >
            新增监工管理
          </a-button>
        </div>
      </div>
      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1150, y: true }"
      >
        <template slot="rate" slot-scope="record">
          {{ record.rate || 0 }}%
        </template>

        <template slot="action" slot-scope="record">
          <div class="flex">
            <a-button type="link" v-permission="'info'">
              <router-link
                :to="{
                  path: '/fowllerInfo',
                  query: { id: record.id }
                }"
              >
                详情
              </router-link>
            </a-button>
             <a-button
                type="link"
                v-permission="'switch'"
                @click="setStatus(record.id, record.status)"
              >
                <span v-if="record.status === 1">禁用</span>
                <span v-else>启用</span>
              </a-button>
          </div>
        </template>
      </my-table>
    </div>

    <!-- <a-modal
      title="分享二维码"
      :visible="modalQrcodeVisible"
      @cancel="onPreviewQRcodeClose"
      :footer="false"
    >
      <img class="qrcode" :src="qrcode" />
    </a-modal> -->

    <!-- <a-modal
      title="编辑"
      :visible="modalChangeVisible"
      @cancel="onChangeClose"
      @ok="onChangeSubmit"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item ref="realName" label="渠道商名称" prop="realName">
          <a-input
            v-model="form.realName"
            placeholder="请输入渠道商名称"
            @blur="
              () => {
                $refs.realName.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="rate" label="渠道商业绩比例" prop="rate">
          <a-input
            v-model="form.rate"
            placeholder="请输入渠道商业绩比例"
            @blur="
              () => {
                $refs.rate.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal> -->
    <!--  -->
    <!-- 同意弹窗 -->
    <!-- <a-modal
      :title="title"
      :width="500"
      :visible="agreeShow"
      :maskClosable="false"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      @cancel="handleModalCancel"
    >
      <a-form-model
        ref="ruleForm"
        :model="formTion"
        :rules="rulesTion"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item ref="name" :label="agreeName" prop="proportion">
          <a-input
            placeholder="请输入1到100的比例"
            v-model="formTion.proportion"
            v-if="agreeNum == 1"
          />
          <a-input
            placeholder="请输入理由"
            v-model="formTion.proportion"
            v-else
          />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="handleModalCancel2"> 取消 </a-button>
        <a-button type="primary" @click="handleModalConfirm2"> 确定 </a-button>
      </template>
    </a-modal> -->
    <!-- 不同意弹窗 -->
  </div>
</template>

<script>
import NP from 'number-precision'
export default {
  data() {
    return {
      dataSource1: [],
      dataSource: [],
      agreeShow: false,
      confirmLoading: false,
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '监工管理名称',
          width: 200,
          dataIndex: 'realName'
        },
        {
          title: '监工管理业绩比例',
          width: 150,
          scopedSlots: { customRender: 'rate' }
        },
        // {
        //   title: '渠道开发的终端数',
        //   width: 150,
        //   scopedSlots: { customRender: 'num' }
        // },
        // {
        //   title: '分享二维码',
        //   scopedSlots: { customRender: 'qrcode' }
        // },
        {
          title: '操作',
          width: 150,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      loading: true,
      modalQrcodeVisible: false,
      qrcode: '',
      modalChangeVisible: false,
      form: {
        realName: '',
        rate: ''
      },
      rules: {
        realName: [
          { required: true, message: '请输入渠道商名称', trigger: 'blur' }
        ],
        rate: [
          { required: true, message: '请输入渠道商业绩比例', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },

      loading2: true,
      visible: false,
      drawerTitle: '',
      dataSource2: '',
      formData: {
        name: ''
      },
      //同意比例
      formTion: {
        proportion: ''
      },
      rulesTion: {
        proportion: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      agreeId: '',
      agreeNum: '',
      agreeName: ''
    }
  },
  created() {
    this.title = this.$getPageTitle()
    this.getChannelBusiness()
  },
  methods: {
    // callback(key) {
    //   // tab切换
    //   this.tabIndex = key
    //   if (key === 0) {
    //     this.getChannelBusiness()
    //   } else {
    //     this.businessApplyList()
    //   }
    // },
    getChannelBusiness() {
      // 获取监工管理列表
      this.$axios.getFowller().then((res) => {
        const l = res.data.data
        l.forEach((e) => {
          if (e.rate) {
            e.rate = NP.times(e.rate, 100)
          }
        })
        this.dataSource = l
        console.log("dataSource",this.dataSource)
        this.loading = false
      })
    },
  

    onPreviewQRcodeClose() {
      // 关闭预览二维码
      this.qrcode = ''
      this.modalQrcodeVisible = false
    },
    onChange(e) {
      this.form = {
        id: e.id,
        realName: e.realName,
        rate: e.rate
      }
      this.modalChangeVisible = true
    },
    onChangeClose() {
      // 取消编辑
      this.$refs.ruleForm.resetFields()
      this.modalChangeVisible = false
    },
    onChangeSubmit() {
      // 新增渠道商
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.addLoading = true
          const data = {
            id: this.form.id,
            realName: this.form.realName,
            rate: NP.divide(this.form.rate, 100)
          }
          this.$axios.changeChannelBusiness(data).then(() => {
            this.$message.success('操作成功')
            this.onChangeClose()
            this.getChannelBusiness()
          })
        }
      })
    },
    onDel(id) {
      this.$confirm({
        title: '确定删除吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: id,
            configType: 1
          }
          this.$axios.delChannel(data).then(() => {
            this.$message.success('操作成功')
            this.getChannelBusiness()
          })
        }
      })
    },
    //同意不同意弹窗
    agree(e, num) {
      this.agreeShow = true
      this.agreeId = e.id
      this.agreeNum = num
      if (num == 1) {
        this.agreeName = '比例'
      } else {
        this.agreeName = '理由'
      }
    },
    handleModalConfirm2() {
      const data = {
        id: this.agreeId,
        status: this.agreeNum
      }
      if (this.agreeNum == 1) {
        if (!/(^[1-9]\d*$)/.test(this.formTion.proportion)) {
          this.$message.error('请输入正整数0也不能输入')
          return false
        }
        if (this.formTion.proportion <= 100) {
          data.rate = this.formTion.proportion / 100
        } else {
          this.$message.error('比例不能超出100')
          return
        }
      } else {
        data.rejectDesc = this.formTion.proportion
      }
      this.$axios.acceptApply(data).then(() => {
        this.$message.success('操作成功')
        this.agreeShow = false
        this.businessApplyList()
      })
    },
    handleModalCancel2() {
      this.agreeShow = false
      this.formTion.proportion = ''
    },
    handleModalCancel() {
      // 取消修改上门时间
      this.agreeShow = false
      this.formTion.proportion = ''
    },
    setStatus(id, status) {
        console.log("uddddd",status)
      // 设置状态
      let title = ''
      if (status === 1) {
        title = '确定禁用？'
        status = 0
      } else {
        title = '确定启用？'
        status = 1
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id,
            status
          }
          this.$axios.getDelFowller(data).then(() => {
            this.$message.success('操作成功')
            this.getChannelBusiness()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.qrcode {
  display: block;
  margin: 0 auto;
}
.flex {
  display: flex;
}
</style>
